import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function ScrollToTop() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/" && location.hash) {
      navigate(location.pathname, { replace: true }); // Removes any hash
    }
  }, [location, navigate]);
  return null;
}
