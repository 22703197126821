import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App"; // ✅ Ensure App.tsx exists
import '/assets/index.css';
 // ✅ Correct way to import


// ✅ Ensure #root exists in index.html
const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Root element (#root) not found. Check index.html.");
}

createRoot(rootElement).render(
  <StrictMode>
    <App />
  </StrictMode>
);
