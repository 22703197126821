import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer/Footer";
import CustomCursor from "./components/CustomCursor";
import ScrollToTop from "./components/ScrollToTop"; // ✅ Import ScrollToTop

// Lazy load components
const Hero = lazy(() => import("./components/Hero"));
const About = lazy(() => import("./components/About/About"));
const Services = lazy(() => import("./components/Services/Services"));
const Blogs = lazy(() => import("./components/Blogs/Blogs"));
const Contact = lazy(() => import("./components/Contact/Contact"));
const BlogDetails = lazy(() => import("./components/Blogs/BlogsDetails"));

// Lazy load service sub-pages
const TelecommunicationSolutions = lazy(() => import("./components/Services/SubPages/telecommunication-solutions"));
const SoftwareSolutions = lazy(() => import("./components/Services/SubPages/software-solutions"));
const AISolutions = lazy(() => import("./components/Services/SubPages/AISolutions"));
const ConsultingSolutions = lazy(() => import("./components/Services/SubPages/consulting-solutions"));
const CyberSecurity = lazy(() => import("./components/Services/SubPages/cybersecurity"));
const NetworkingConnectivity = lazy(() => import("./components/Services/SubPages/networking-connectivity"));

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* ✅ Ensures page starts from top on load & navigation */}

      <div className="min-h-screen bg-background relative">
        {/* Custom Cursor */}
        <CustomCursor />

        {/* Navbar */}
        <Navbar />

        {/* Main Content with Suspense for Lazy Loading */}
        <Suspense fallback={<div className="text-center py-10">Loading...</div>}>
          <Routes>
            {/* Home Page */}
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <About />
                  <Services />
                  <Blogs />
                  <Contact />
                </>
              }
            />

            {/* Service Sub-Pages */}
            <Route path="/services/AI-solutions" element={<AISolutions />} />
            <Route path="/services/software-solutions" element={<SoftwareSolutions />} />
            <Route path="/services/telecommunication-solutions" element={<TelecommunicationSolutions />} />
            <Route path="/services/networking-connectivity" element={<NetworkingConnectivity />} />
            <Route path="/services/cybersecurity" element={<CyberSecurity />} />
            <Route path="/services/consulting-solutions" element={<ConsultingSolutions />} />

            {/* Blog Routes */}
            <Route path="/blogs" element={<div className="pt-20"><Blogs /></div>} />
            <Route path="/blogs/:id" element={<div className="pt-20"><BlogDetails /></div>} />
          </Routes>
        </Suspense>

        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
