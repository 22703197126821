import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Menu,
  X,
  ChevronDown,
  Globe2,
  Home,
  Briefcase,
  Info,
  Phone,
  Code2,
  Globe,
  Shield,
  LineChart,
  Wifi,
  LucideIcon,
  Bot,
  BookOpen,
} from "lucide-react";

interface ServiceItem {
  title: string;
  description: string;
  Icon: LucideIcon;
  link: string;
}

interface MenuItem {
  title: string;
  href: string;
  icon: JSX.Element;
  isPage?: boolean;
  subItems?: ServiceItem[];
}

const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState<"en" | "ar">("en");
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
    setIsServicesMenuOpen(false);
  }, [location]);

  const scrollToSection = (sectionId: string) => {
    setIsOpen(false);
    setIsServicesMenuOpen(false);

    if (location.pathname !== "/") {
      window.location.href = `/${sectionId}`;
      return;
    }

    const element = document.getElementById(sectionId.replace("#", ""));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.getElementById(location.hash.replace("#", ""));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, [location]);

  const services: ServiceItem[] = [
    {
      title:
        language === "en" ? "Cybersecurity Solutions" : "حلول الأمن السيبراني",
      description:
        language === "en"
          ? "Protect your business from cyber threats with advanced solutions powered by Fortinet, Pro-Line, and Monitra. From AI-driven firewalls to secure cloud infrastructures, we ensure comprehensive digital protection."
          : "حماية أعمالك من التهديدات السيبرانية باستخدام حلول متقدمة مدعومة من Fortinet، Pro-Line، وMonitra. من الجدران النارية المدعومة بالذكاء الاصطناعي إلى البنية التحتية السحابية الآمنة، نضمن لك حماية رقمية شاملة.",
      Icon: Shield,
      link: "/services/cybersecurity",
    },
    {
      title:
        language === "en"
          ? "Networking and IT Infrastructure Solutions"
          : " حلول الشبكات والبنية التحتية لتكنولوجيا المعلومات",
      description:
        language === "en"
          ? "Build a strong foundation with high-speed networks, scalable IT infrastructure, and secure data centers. Leverage solutions from FiberTech Qatar and top hardware providers for seamless business operations."
          : "بناء أساس قوي باستخدام شبكات عالية السرعة، بنية تحتية قابلة للتطوير، ومراكز بيانات آمنة. استفد من حلول FiberTech Qatar وأفضل مزودي الأجهزة لضمان سير عملياتك بسلاسة.",
      Icon: Wifi,
      link: "/services/networking-connectivity",
    },
    {
      title:
        language === "en"
          ? "AI and Machine Learning Solutions"
          : "حلول الذكاء الاصطناعي وتعلم الآلة",
      description:
        language === "en"
          ? "Drive innovation with AI-powered tools from ThinkAI. From predictive analytics to business automation, our solutions enhance productivity and give you a competitive edge."
          : "قم بتحفيز الابتكار باستخدام الأدوات المدعومة بالذكاء الاصطناعي من ThinkAI. من التحليلات التنبؤية إلى أتمتة الأعمال، تعمل حلولنا على تعزيز الإنتاجية ومنحك ميزة تنافسية.",
      Icon: Bot,
      link: "/services/AI-solutions",
    },
    {
      title:
        language === "en"
          ? "Software and Application Development"
          : ". تطوير البرمجيات والتطبيقات",
      description:
        language === "en"
          ? "Enhance efficiency with custom software, ERP systems, and user-friendly mobile and web applications. Our innovative IoT and AI-driven solutions help streamline operations and drive growth."
          : "تحسين الكفاءة باستخدام البرمجيات المخصصة، أنظمة تخطيط موارد المؤسسات (ERP)، وتطبيقات الويب والجوال سهلة الاستخدام. تساعد حلولنا المبتكرة في إنترنت الأشياء والذكاء الاصطناعي في تبسيط العمليات وتحفيز النمو.",
      Icon: Code2,
      link: "/services/software-solutions",
    },
    {
      title:
        language === "en"
          ? "Telecommunication and Satellite Solutions"
          : "حلول الاتصالات والأقمار الصناعية",
      description:
        language === "en"
          ? "Ensure reliable communication anywhere with satellite, broadband, and radio solutions from L3Harris. Designed for remote environments, our systems keep your business connected."
          : "ضمان الاتصال الموثوق في أي مكان باستخدام حلول الأقمار الصناعية، النطاق العريض، والراديو من L3Harris. صممت أنظمتنا للبيئات النائية لضمان بقاء أعمالك متصلة.",
      Icon: Globe,
      link: "/services/telecommunication-solutions",
    },
    {
      title:
        language === "en"
          ? "Consulting and Managed Services"
          : "الاستشارات والخدمات المدارة",
      description:
        language === "en"
          ? "Optimize your IT strategy with tailored consulting and proactive managed services. We provide seamless system management and robust disaster recovery to ensure uninterrupted operations."
          : "تحسين استراتيجية تكنولوجيا المعلومات الخاصة بك باستخدام استشارات مخصصة وخدمات مدارة استباقية. نقدم لك إدارة أنظمة سلسة وتعافي فعال من الكوارث لضمان استمرارية العمليات دون انقطاع.",
      Icon: LineChart,
      link: "/services/consulting-solutions",
    },
  ];

  const menuItems: MenuItem[] = [
    {
      title: language === "en" ? "Home" : "الرئيسية",
      href: "/#home",
      icon: <Home className="w-5 h-5 mr-2 text-[#B3955F]" />,
      isPage: true,
    },
    {
      title: language === "en" ? "Services & Solutions" : "الخدمات والحلول",
      href: "#services",
      icon: <Briefcase className="w-5 h-5 mr-2 text-[#B3955F]" />,
      subItems: services,
    },
    {
      title: language === "en" ? "About" : "من نحن",
      href: "#about",
      icon: <Info className="w-5 h-5 mr-2 text-[#B3955F]" />,
    },
    {
      title: language === "en" ? "Blogs" : "المدونة",
      href: "#blogs",
      icon: <BookOpen className="w-5 h-5 mr-2 text-[#B3955F]" />, // Example with a "Book Open" icon
    },

    {
      title: language === "en" ? "Contact" : "اتصل بنا",
      href: "#contact",
      icon: <Phone className="w-5 h-5 mr-2 text-[#B3955F]" />,
    },
  ];

  const handleNavigation = (
    item: MenuItem,
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setIsOpen(false);
    setIsServicesMenuOpen(false);

    if (item.isPage) {
      window.location.href = item.href;
    } else if (item.href.startsWith("#")) {
      scrollToSection(item.href);
    }
  };

  const handleServiceClick = () => {
    setIsServicesMenuOpen(false);
    setIsOpen(false);
  };

  return (
    <nav
      className={`fixed w-full ${
        language === "ar" ? "text-right rtl" : "text-left ltr"
      } bg-white/90 backdrop-blur-sm z-50 shadow-md`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex-shrink-0">
            <div
              onClick={() => {
                setIsOpen(false);
                setIsServicesMenuOpen(false);
                navigate("/#home");
              }}
              className="block transition-transform transform hover:scale-110"
            >
              <img
                src="https://res.cloudinary.com/dhrsf44wi/image/upload/v1740747004/MK/Mk-logo_redaqu.svg"
                alt="Logo"
                className="h-12 w-auto animate-fade-in"
              />
            </div>
          </div>
          <div className="hidden md:flex items-center space-x-6">
            {menuItems.map((item, index) => (
              <div
                key={index}
                className="relative group"
                onMouseEnter={() =>
                  item.subItems && setIsServicesMenuOpen(true)
                }
                onMouseLeave={() =>
                  item.subItems && setIsServicesMenuOpen(false)
                }
              >
                <a
                  href={item.href}
                  onClick={(e) => handleNavigation(item, e)}
                  className="flex items-center text-[#333333] hover:text-[#B3955F] px-3 py-2 rounded-md text-sm font-medium transition-all transform hover:scale-105"
                >
                  {item.icon}
                  {item.title}
                  {item.subItems && (
                    <ChevronDown className="w-4 h-4 ml-1 text-[#B3955F]" />
                  )}
                </a>
                {item.subItems && isServicesMenuOpen && (
                  <div className="absolute left-1/2 transform -translate-x-1/2 w-screen bg-white shadow-xl transition-all duration-300">
                    <div className="max-w-7xl mx-auto px-8 py-12">
                      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {item.subItems.map((service, idx) => (
                          <div
                            key={idx}
                            onClick={() => {
                              handleServiceClick();
                              navigate(service.link);
                            }}
                            className="group/card flex flex-col p-6 rounded-lg hover:bg-gray-50 transition-all duration-300"
                          >
                            <div className="flex items-center mb-4">
                              <service.Icon className="w-8 h-8 text-[#B3955F] group-hover/card:scale-110 transition-transform duration-300" />
                              <h3 className="ml-3 text-lg font-medium text-gray-900 group-hover/card:text-[#B3955F] transition-colors">
                                {service.title}
                              </h3>
                            </div>
                            <p className="text-sm text-gray-500 group-hover/card:text-gray-600 transition-colors">
                              {service.description}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
            <button
              onClick={() => setLanguage(language === "en" ? "ar" : "en")}
              className="flex items-center text-[#333333] hover:text-[#B3955F] px-3 py-2 rounded-md text-sm font-medium transition-all transform hover:scale-105"
            >
              <Globe2 className="w-5 h-5 mr-1" />
              {language === "en" ? "AR" : "EN"}
            </button>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-[#333333] hover:text-[#B3955F] focus:outline-none transition-transform transform active:scale-90"
            >
              {isOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden bg-white">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {menuItems.map((item, index) => (
              <div key={index}>
                <a
                  href={item.href}
                  onClick={(e) => handleNavigation(item, e)}
                  className="flex items-center text-[#333333] hover:text-[#B3955F] px-3 py-2 rounded-md text-base font-medium"
                >
                  {item.icon}
                  {item.title}
                </a>
                {item.subItems && isOpen && (
                  <div className="pl-6 space-y-2 mt-2">
                    {item.subItems.map((service, idx) => (
                      <div
                        key={idx}
                        onClick={() => {
                          handleServiceClick();
                          navigate(service.link);
                        }}
                        className="flex items-center text-gray-600 hover:text-[#B3955F] py-2"
                      >
                        <service.Icon className="w-5 h-5 mr-2" />
                        <span>{service.title}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
