import { Facebook, Linkedin, Instagram, X } from "lucide-react";
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion";

const Footer = () => {
  const location = useLocation();

  const scrollToSection = (sectionId: string) => {
    if (location.pathname !== '/') {
      window.location.href = `/${sectionId}`;
      return;
    }

    const element = document.getElementById(sectionId.replace('#', ''));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="bg-[#424242] text-white relative">
      {/* Pattern Background Container */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Pattern Background with Animation - Responsive for all screens */}
        <motion.div
          className="absolute inset-0 w-full h-full opacity-10"
          style={{
            backgroundImage: "url('/Public/MK-Pattern.svg')",
            backgroundSize: "100% auto",  // Full width, auto height
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            transform: "scale(1)", // Start at normal scale
            transformOrigin: "center",
            maxWidth: "100vw",
            maxHeight: "100%",
          }}
          animate={{
            scale: [1, 1.05, 1], // Subtle scale animation
            opacity: [0.1, 0.15, 0.1],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
      </div>

      {/* Content Container */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo Section */}
          <div>
            <img
              src="https://res.cloudinary.com/dhrsf44wi/image/upload/v1740747004/MK/MK-Logo-Footer_quc1f6.svg"
              alt="MK Information System Logo"
              className="w-32 mb-4"
            />
            <p className="text-[#F5F5F5]">
              Leading IT solutions provider in Qatar and the Middle East.
            </p>
          </div>

          {/* Quick Links Section */}
          <div>
            <h4 className="text-lg font-semibold text-[#D4AF37] mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li>
                <a 
                  href='#home'
                  className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors"
                >
                  Home
                </a>
              </li>
              <li>
                <a 
                  href='#services'
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('services');
                  }}
                  className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors"
                >
                  Services & Solutions
                </a>
              </li>
              <li>
                <a 
                  href='#about'
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('about');
                  }}
                  className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors"
                >
                  About
                </a>
              </li>
              <li>
                <a 
                  href='#Blogs'
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('Blogs');
                  }}
                  className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors"
                >
                  Blogs
                </a>
              </li>
              <li>
                <a 
                  href='#contact'
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('contact');
                  }}
                  className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>

          {/* Services and Solutions Section */}
          <div>
            <h4 className="text-lg font-semibold text-[#D4AF37] mb-4">Services and Solutions</h4>
            <ul className="space-y-2">
              <li>
                <a href="/services/cybersecurity" className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors">
                  Cybersecurity Solutions
                </a>
              </li>
              <li>
                <a href="/services/networking-connectivity" className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors">
                Networking and IT Infrastructure Solutions
                </a>
              </li>
              <li>
                <a href="/services/AI-solutions" className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors">
                AI and Machine Learning Solutions
                </a>
              </li>
              <li>
                <a href="/services/software-solutions" className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors">
                Software and Application Development
                </a>
              </li>
              <li>
                <a href="/services/telecommunication-solutions" className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors">
                Telecommunication and Satellite Solutions
                </a>
              </li>
              <li>
                <a href="/services/consulting-solutions" className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors">
                Consulting and Managed Services
                </a>
              </li>
            </ul>
          </div>

          {/* Social Media Section */}
          <div>
            <h4 className="text-lg font-semibold text-[#D4AF37] mb-4">Connect With Us</h4>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/MKinfoSystems"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors"
              >
                <Facebook className="w-6 h-6" />
              </a>
              <a
                href="https://x.com/MKinfoSystems"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors"
              >
                <X className="w-6 h-6" />
              </a>
              <a
                href="https://www.linkedin.com/company/mk-info-systems/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors"
              >
                <Linkedin className="w-6 h-6" />
              </a>
              <a
                href="https://www.instagram.com/mkinfosystems/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#F5F5F5] hover:text-[#B8945C] transition-colors"
              >
                <Instagram className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="mt-8 pt-8 border-t border-[#B8945C] text-center text-[#F5F5F5]">
          <p>&copy; {new Date().getFullYear()} MK Information System. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;